import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { ContentfulPageSection, Protocol } from '~/types/types';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { ProtocolCard } from '~/components/ProtocolCard/ProtocolCard';
import { StyledFeaturedProtocols } from './FeaturedProtocols.style';

export const FeaturedProtocols: React.FC<FeaturedProtocolsProps> = ({
  section,
}) => {
  const data = useStaticQuery(graphql`
    query FeaturedProtocols {
      contentfulFeaturedProtocols {
        featuredProtocols {
          ...ProtocolCardFragment
        }
      }
    }
  `);

  const { featuredProtocols }: { featuredProtocols: Protocol[] } =
    data.contentfulFeaturedProtocols;

  const protocols = (section.media as Protocol[]) || featuredProtocols;

  return (
    <StyledFeaturedProtocols>
      {section.description && (
        <ShowOnScroll
          className="text__content"
          dangerouslySetInnerHTML={{
            __html: section.description.childMarkdownRemark.html,
          }}
        />
      )}
      <div className="protocol-list--grid">
        {protocols.map((p, i) => {
          return (
            <ShowOnScroll delay={i * 100} key={p.id}>
              <ProtocolCard protocol={p} />
            </ShowOnScroll>
          );
        })}
      </div>
    </StyledFeaturedProtocols>
  );
};

interface FeaturedProtocolsProps {
  section: ContentfulPageSection;
}
