import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledFeaturedProtocols = styled.div`
  margin-top: ${pxtorem(30)};

  @media (min-width: 768px) {
    padding-inline: min(5%, ${pxtorem(68)});
    margin: ${pxtorem(65)} 0;
  }

  .text__content {
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 60%;
    }

    h1 {
      text-align: center;
      font: 300 ${pxtorem(60)} / 97% var(--primaryFont);
      font-size: clamp(${pxtorem(40)}, 5vw, ${pxtorem(60)});
      margin: 0;
      margin-top: ${pxtorem(10)};
      em {
        font-size: clamp(${pxtorem(34)}, 5vw, ${pxtorem(52)});
      }
    }
    p {
      text-align: center;
      font-size: ${pxtorem(20)};
      margin: 0;
      margin-top: ${pxtorem(10)};
    }
  }

  .protocol-list--grid {
    display: grid;

    place-content: center;
    place-items: center;
    grid-template-columns: minmax(360px, 1fr);
    gap: ${pxtorem(40)};
    > *  {
      width: 100%;
    }
    justify-items: center;
    margin: ${pxtorem(40)} auto ${pxtorem(40)};

    @media (min-width: 768px) {
      margin: ${pxtorem(60)} auto;
    }

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(360px, 1fr));
      gap: ${pxtorem(80)} ${pxtorem(20)};
    }

    @media (min-width: 1280px) {
      grid-template-columns: repeat(3, 360px);
    }
  }
`;
