import styled from 'styled-components';
import { desktopHover, pxtorem } from '../../utils/tools';
import { StyledLink } from '../Generic/Cta/Cta.style';

export const StyledValuesModule = styled.div`
  padding-inline: min(5%, ${pxtorem(68)});
  margin: ${pxtorem(30)} 0 ${pxtorem(65)};
  text-align: center;

  @media (min-width: 768px) {
    margin: ${pxtorem(65)} 0;
  }

  .values__description {
    h1 {
      text-align: center;
      margin: 0;
      font-size: ${pxtorem(40)};

      @media (min-width: 768px) {
        font-size: ${pxtorem(60)};
      }

      em {
        font-size: 0.86em;
      }
    }

    p {
      text-align: center;
      font-size: ${pxtorem(20)};
      margin: 0;
      margin-top: ${pxtorem(10)};
    }

    & + .values__media {
      margin-top: ${pxtorem(40)};
    }
  }

  .values__media {
    @media (min-width: 768px) {
      display: flex;
      max-width: 1400px;
      margin: 0 auto;
      justify-content: space-around;
    }
  }
  .values__mediaCard {
    @media (max-width: 767px) {
      margin-bottom: ${pxtorem(60)};
    }

    text-align: center;

    @media (min-width: 768px) {
      flex: 0 1 360px;
    }
  }

  .values__mediaShape {
    position: relative;
    
    svg {
      width: 45%;
      aspect-ratio: 1 / 1;
      height: auto;
      animation: rotate 30s infinite linear;
    }

    .values__mediaText {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .values__mediaText {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: white;
        font-size: ${pxtorem(16)};
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }

  .values__mediaContent {
    @media (max-width: 767px) {
      margin-inline: var(--gutter);
    }

    p {
      margin: ${pxtorem(20)} auto;
      font-size: ${pxtorem(16)};
      line-height: 1.125;
      font-weight: 400;
      color: var(--darkGray);

      strong {
        font-weight: 500;
        text-transform: uppercase;
        font-size: ${pxtorem(16)};
      }

      @media (min-width: 768px) {
        font-size: ${pxtorem(20)};
        width: 80%;
      }

      &:first-child {
        margin-bottom: 0;
      }

      &:last-child {
        margin-top: 8px;
      }
    }

    a {
      font-size: ${pxtorem(16)};
      font-weight: 500;
      line-height: 1;
      text-decoration: underline 0.1rem solid var(--darkGray);
      text-underline-offset: 0.3rem;
      color: var(--darkGray);
      transition-property: color, text-decoration-color;
      padding: 0;
      ${desktopHover(`
        background: transparent;
        color: var(--blackHover);
        text-decoration-color:rgba(0, 0, 0, 0);
      `)}

      @media (min-width: 768px) {
        font-size: ${pxtorem(20)};
        line-height: 1.2;
      }
    }
  }

  ${StyledLink} {
    font-weight: 500;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }
`;
