import React from 'react';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { StyledTextBody } from './TextBody.style';

export const TextBody: React.FC<TextBodyProps> = ({ section }) => {
  return (
    <StyledTextBody>
      {section.description && (
        <ShowOnScroll
          delay={200}
          className="text__content"
          dangerouslySetInnerHTML={{
            __html: section.description.childMarkdownRemark.html,
          }}
        />
      )}
    </StyledTextBody>
  );
};

interface TextBodyProps {
  section: {
    title?: string;
    description?: MarkdownData;
    cta?: {
      raw: string;
    };
  };
}
