import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledTextBody = styled.div`
  .text__content {
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    h1,
    h2,
    h3 {
      grid-column-start: 1;
      font-size: ${pxtorem(16)};
      line-height: 1.1875;
      font-weight: 500;
      text-transform: uppercase;
      margin-top: calc(var(--gutter) * 4);
      margin-bottom: calc(var(--gutter) * 2);
    }

    p {
      grid-column-start: 2;
      font-size: ${pxtorem(20)};
      line-height: 1.18;
    }

    @media (min-width: 768px) {
      h1,
      h2,
      h3 {
        margin-top: ${pxtorem(80)};

        & + p {
          margin-top: ${pxtorem(80)};
        }
      }
      p {
        margin: 0 0 calc(var(--gutter) * 1.5) 0;
      }
    }
  }
`;
