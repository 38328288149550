import React from 'react';
import { FeaturedProducts } from './FeaturedProducts';

import { IProduct, ContentfulPageSection } from '~/types/types';

export const FeaturedProductsSection: React.FC<
  FeaturedProductsSectionProps
> = ({ section }) => {
  // One of the medias can be use for the Background
  const bgContentCard = section.media.find(
    m => m.__typename === 'ContentfulContentCard'
  );

  // Make sure the rest are products
  const products = section.media.filter(
    m => m.__typename === 'ContentfulProduct'
  ) as IProduct[];

  return (
    <FeaturedProducts
      title={section.title}
      description={section.description}
      bgImage={bgContentCard?.media}
      products={products}
    />
  );
};

export interface FeaturedProductsSectionProps {
  section: ContentfulPageSection;
}
