import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { OurStoryProps } from '../../pages/our-story';
import { GatsbyImageData, ThemeColor } from '../../types/types';
import { Cta } from '../Generic/Cta/Cta';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { StyledPullQuoteFull } from './PullQuoteFull.style';

export const PullQuoteFull: React.FC<PullQuoteFullProps> = ({
  section,
  cardColor,
}) => {
  const [, hyperLink] = JSON.parse(section.cta.raw).content?.[0].content;
  const [{ label, description, media }] = section.media;
  const [isImageVisible, setIsImageVisible] = useState(false);
  const { data, content } = hyperLink;
  const ctaText = content?.[0].value;
  const ctaHref = data.uri;
  return (
    <StyledPullQuoteFull cardColor={cardColor}>
      <div className="pull-quote-container">
        <div className="pull-quote">
          <ShowOnScroll>
            <span className="section-label">{label}</span>
          </ShowOnScroll>
          <ShowOnScroll
            delay={150}
            className="pull-quote--content"
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
          <ShowOnScroll className="pull-quote__cta-container" delay={300}>
            <Cta className="pull-quote__cta" href={ctaHref}>
              {ctaText}
            </Cta>
          </ShowOnScroll>
        </div>
      </div>
      <ShowOnScroll
        passive
        delay={100}
        onVisible={() => setIsImageVisible(true)}
        onNoLongerVisible={() => setIsImageVisible(false)}
        className={`img-container ${isImageVisible ? 'visible' : ''}`}
      >
        <GatsbyImage
          image={media.gatsbyImageData as GatsbyImageData}
          alt={label}
          className="section__image"
        />
      </ShowOnScroll>
    </StyledPullQuoteFull>
  );
};

interface PullQuoteFullProps {
  section: OurStoryProps['data']['page']['sections'][0];
  cardColor: ThemeColor;
}
