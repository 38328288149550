import React from 'react';

import { FaqBlock } from '~/components/Generic/FaqBlock/FaqBlock';
import { ContentCard, ContentfulPageSection } from '~/types/types';
import { FaqQuestion } from '~/pages/faq';

import { StyledFaqSection } from './FaqSection.styled';

export const FaqSection: React.FC<FaqSectionProps> = ({ section }) => {
  const questions: FaqQuestion[] = section.media.map(
    ({ id, label, description }: ContentCard) => {
      return {
        id,
        question: {
          question: label,
        },
        answer: description,
      };
    }
  );

  return (
    <StyledFaqSection>
      <h2>{section.title}</h2>
      <FaqBlock questions={questions} />
    </StyledFaqSection>
  );
};

interface FaqSectionProps {
  section: ContentfulPageSection;
}
