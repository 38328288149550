import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledTextModule = styled.div<StyledTextModuleProps>`
  margin: var(--gutter) 0;

  @media (min-width: 768px) {
    padding-inline: min(5%, ${pxtorem(68)});
    margin: ${pxtorem(65)} 0;
  }

  @media (min-width: 1920px) {
    padding-inline: 0;

    .text__shapes-container,
    .text__content p {
      max-width: 80%;
      margin-inline: auto;
    }
  }

  ${props =>
    props.centered &&
    `
    text-align: center;
  `}

  ${props =>
    props.showShapes &&
    `
    @media (min-width: 768px) {
      margin-bottom: ${pxtorem(40)};
    }
  `}

  .text__title {
    font-size: ${pxtorem(12)};
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: ${pxtorem(16)};
    }
  }

  .text__content p {
    font: 300 ${pxtorem(28)} / 97% var(--primaryFont);
    margin: 0;
    margin-top: ${pxtorem(10)};
    ${p => p.showShapes && `margin-inline: auto;`}
    @media (min-width: 768px) {
      font-size: clamp(${pxtorem(40)}, 5vw, ${pxtorem(60)});
    }
  }

  .text__cta {
    margin-top: ${pxtorem(30)};

    @media (max-width: 767px) {
      a {
        font-size: ${pxtorem(16)};
        width: auto;
      }
    }
  }
  .text__shapes-container {
    margin-top: ${pxtorem(80)};
  }

  .text__shapes {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: ${pxtorem(10)};

    .spring {
      svg {
        transform: scale(0) rotate(-170deg);
      }

      &.visible svg {
        animation: shapeAnimationSpring 0.6s var(--cubic-ease) forwards;
      }
    }

    .scale {
      svg {
        transform: scale(0);
      }

      &.visible svg {
        animation: shapeAnimationScale 0.5s var(--cubic-ease) forwards;
      }
    }

    .mobile-scale {
      svg {
        transform: scale(0) translateY(-10%);
      }

      &.visible svg {
          animation: shapeAnimationScaleMobile 0.5s var(--cubic-ease) forwards;
      }
    }

    &--desktop {
      display: none;

      @media (min-width: 768px) {
        display: flex;
      }
    }

    &--mobile {

      &:first-child {}
      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  .text__shape {
    @media (max-width: 767px) {
      flex: 1 1 32%;
    }

    flex: 1 1 auto;

    svg {
      width: 100%;
      height: auto;
    }
    & + .text__shape {
      padding-left: 3%;
      padding-top: 3%;

      @media (min-width: 768px) {
        padding-left: 1%;
      }
    }

    &--stack {
      display: flex;
      flex-direction: column;

      svg + svg {
        margin-top: ${pxtorem(12)};
      }
    }
  }

`;

interface StyledTextModuleProps {
  centered?: boolean;
  showShapes?: boolean;
}
