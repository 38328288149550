import React, { useEffect } from 'react';
import { StyledAccordion } from './Accordion.styled';
import gsap from 'gsap';

export const Accordion: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  //#region Hooks / Lifecycles
  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }
  //#endregion

  //#region Templating
  return (
    <StyledAccordion className="accordion">
      {children}
    </StyledAccordion>
  )
  //#endregion
}

export const closeAllAccordions = (scope: HTMLElement | Document = document): void => {
  const dropdowns: NodeListOf<HTMLElement> = scope.querySelectorAll('.accordionItem__content--opened');
  for (let i = 0; i < dropdowns.length; i++) {
    dropdowns[i].classList.remove('accordionItem__content--opened');
    dropdowns[i].previousElementSibling?.classList.remove('accordionItem__trigger--opened');
  }

  gsap.to(dropdowns, {
    height: '0px',
    duration: 1,
    ease: 'power4.out'
  })
}