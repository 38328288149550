import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledFaqSection = styled.div`
  @media (max-width: 1920px) {
    padding: 0 min(5%, 102px);
  }

  h2 {
    margin-bottom: ${pxtorem(42)};
  }
`;
