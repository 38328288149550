import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledVideoSection = styled.div`
  text-align: center;
  margin: ${pxtorem(52)} 0;

  h2 {
    margin-bottom: ${pxtorem(13)};
  }

  p {
    margin-inline: auto;
    max-width: 557px;
    font-size: ${pxtorem(16)};
    margin-top: 0;

    @media (min-width: 768px) {
      font-size: ${pxtorem(20)};
    }
  }

  iframe {
    margin-top: ${pxtorem(28)};
    aspect-ratio: 16/9;
    width: 100%;
    height: auto;
    max-width: 785px;
  }
`;
