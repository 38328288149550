import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { KitProductCard } from '~/components/KitProductCard/KitProductCard';
import { ContentfulPageSection, Kit } from '~/types/types';

import { StyledFeaturedKits } from './FeaturedKits.style';

export const FeaturedKits: React.FC<FeaturedKitsProps> = ({ section, noPrefetch }) => {
  const data = useStaticQuery(graphql`
    query FeaturedKits {
      contentfulFeaturedKits {
        featuredKits {
          ...KitCardFragment
        }
      }
    }
  `);

  const { featuredKits }: { featuredKits: Kit[] } = data.contentfulFeaturedKits;

  const Kits = (section?.media as Kit[]) || featuredKits;

  if (!Kits || !Kits.length) {
    return null;
  }

  return (
    <StyledFeaturedKits>
      {Kits.map(kit => (
        <KitProductCard kit={kit} key={kit.slug} noPrefetch={noPrefetch} />
      ))}
    </StyledFeaturedKits>
  );
};

interface FeaturedKitsProps {
  section?: ContentfulPageSection;
  noPrefetch?: boolean;
}
