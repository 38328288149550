import React from 'react';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { StyledTextModule } from './TextModule.style';

import Shape2 from '~/assets/images/shapes/shape2.svg';
import Shape3 from '~/assets/images/shapes/shape3.svg';
import Shape4 from '~/assets/images/shapes/shape4.svg';
import Shape5 from '~/assets/images/shapes/shape5.svg';
import theme from '~/styles/theme';
import { CtaProps } from '../Generic/Cta/Cta';
import { CtaParsed } from '../Generic/Cta/CtaParsed';

const desktopShapes: ShapesMap[] = [
  {
    shapes: <Shape5 fill={theme.colors['orange']} />,
  },
  {
    shapes: <Shape4 fill={theme.colors['red']} />,
  },
  {
    shapes: <Shape3 fill={theme.colors['white']} />,
  },
  {
    shapes: <Shape2 fill={theme.colors['green']} />,
  },
  {
    shapes: <Shape4 fill={theme.colors['white']} />,
  },
  {
    shapes: (
      <>
        <Shape2 fill={theme.colors['red']} />
        <Shape2 fill={theme.colors['orange']} />
      </>
    ),
    className: 'text__shape--stack',
  },
  {
    shapes: <Shape5 fill={theme.colors['green']} />,
  },
];

const mobileShapesFirstRow: ShapesMap[] = [
  {
    shapes: <Shape4 fill={theme.colors['white']} />,
  },
  {
    shapes: <></>,
  },
  {
    shapes: <Shape5 fill={theme.colors['green']} />,
  },
];

const mobileShapesSecondRow: ShapesMap[] = [
  {
    shapes: <Shape5 fill={theme.colors['orange']} />,
  },
  {
    shapes: <Shape4 fill={theme.colors['red']} />,
  },
  {
    shapes: (
      <>
        <Shape2 fill={theme.colors['orange']} />
        <Shape2 fill={theme.colors['white']} />
      </>
    ),
    className: 'text__shape--stack',
  },
];

export const TextModule: React.FC<TextModuleProps> = ({
  section,
  centered,
  showShapes,
  ctaVariant = 'secondary',
}) => {
  return (
    <StyledTextModule centered={centered} showShapes={showShapes}>
      {section.title && (
        <ShowOnScroll>
          <span className="text__title">{section.title}</span>
        </ShowOnScroll>
      )}

      {section.description && (
        <ShowOnScroll
          delay={200}
          className="text__content"
          dangerouslySetInnerHTML={{
            __html: section.description.childMarkdownRemark.html,
          }}
        />
      )}

      {section.cta && (
        <div className="text__cta">
          <ShowOnScroll delay={400}>
            <CtaParsed
              raw={section.cta.raw}
              variant={ctaVariant}
              size="large"
            />
          </ShowOnScroll>
        </div>
      )}

      {showShapes && (
        <div className="text__shapes-container">
          <div className="text__shapes text__shapes--desktop">
            {desktopShapes.map(({ shapes, className }, i) => {
              const baseDelay = i === desktopShapes.length - 1 ? 800 : 600;
              const animationClass =
                i === desktopShapes.length - 1 ? 'spring' : 'scale';

              return (
                <ShowOnScroll
                  className={`text__shape ${className ?? ''} ${animationClass}`}
                  passive
                  delay={i * 200 + baseDelay}
                  key={`desktopShape_${i}`}
                >
                  {shapes}
                </ShowOnScroll>
              );
            })}
          </div>
          <div className="text__shapes text__shapes--mobile">
            {mobileShapesFirstRow.map(({ shapes, className }, i) => {
              const baseDelay = 700;
              return (
                <ShowOnScroll
                  className={`text__shape ${className ?? ''} mobile-scale`}
                  passive
                  delay={baseDelay + i * 200}
                  key={`mobile_row_1-${i}`}
                >
                  {shapes}
                </ShowOnScroll>
              );
            })}
          </div>
          <div className="text__shapes text__shapes--mobile">
            {mobileShapesSecondRow.map(({ shapes, className }, i) => {
              const baseDelay = 500;
              return (
                <ShowOnScroll
                  className={`text__shape ${className ?? ''} mobile-scale`}
                  passive
                  delay={baseDelay + i * 200}
                  key={`mobile_row_0-${i}`}
                >
                  {shapes}
                </ShowOnScroll>
              );
            })}
          </div>
        </div>
      )}
    </StyledTextModule>
  );
};

interface TextModuleProps {
  section: {
    title?: string;
    description?: MarkdownData;
    cta?: {
      raw: string;
    };
  };
  centered?: boolean;
  showShapes?: boolean;
  ctaVariant?: CtaProps['variant'];
}

interface ShapesMap {
  className?: string;
  shapes: JSX.Element;
}
