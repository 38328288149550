import React, { Component } from 'react';
import { ContentfulPageSection, SectionType } from '~/types/types';

import { Section, SectionProps } from '../../styles/Section.styled';
import { Wrapper, WrapperProps } from '../../styles/Wrapper.styled';

import { AutoCarousel } from '../AutoCarousel/AutoCarousel';
import { FaqSection } from '../FaqSection/FaqSection';
import { FeaturedArticles } from '../FeaturedArticles/FeaturedArticles';
import { FeaturedKits } from '../FeaturedKits/FeaturedKits';
import { FeaturedProductsSection } from '../FeaturedProducts/FeaturedProductsSection';
import { FeaturedProtocols } from '../FeaturedProtocols/FeaturedProtocols';
import { ImageBanner } from '../ImageBanner/ImageBanner';
import { ImageGrid } from '../ImageGrid/ImageGrid';
import { IngredientsModule } from '../IngredientsModule/IngredientsModule';
import { Pillars } from '../Pillars/Pillars';
import { PullQuote } from '../PullQuote/PullQuote';
import { PullQuoteBlob } from '../PullQuoteBlob/PullQuoteBlob';
import { PullQuoteFull } from '../PullQuoteFull/PullQuoteFull';
import { ShopBy } from '../ShopBy/ShopBy';
import { SocialContact } from '~/components/Generic/SocialContact/SocialContact';
import { TeamCarousel } from '../TeamCarousel/TeamCarousel';
import { TextBody } from '../TextBody/TextBody';
import { TextModule } from '../TextModule/TextModule';
import { Values } from '~/components/Values/Values';
import { VideoCarousel } from '~/components/VideoCarousel/VideoCarousel';
import { VideoSingle } from '~/components/VideoSingle/VideoSingle';
import { ValuePropGrid } from '~/components/ValuePropGrid/ValuePropGrid';

const ComponentsMap: ComponentMapData = {
  'carousel-auto': { component: AutoCarousel, wrapperProps: { gutter: false } },
  'carousel-team': { component: TeamCarousel },
  faq: { component: FaqSection },
  'featured-articles': {
    component: FeaturedArticles,
  },

  'featured-kits': {
    component: FeaturedKits,
    sectionProps: { bg: 'green' },
  },
  'featured-products': {
    component: FeaturedProductsSection,
    wrapperProps: { gutter: false, rounded: false },
  },
  'featured-protocols': { component: FeaturedProtocols },
  'image-banner': {
    component: ImageBanner,
    wrapperProps: { gutter: false, rounded: false },
  },
  'image-grid': {
    component: ImageGrid,
    sectionProps: { gutter: false },
    wrapperProps: { width: 'normal' },
  },
  ingredients: { component: IngredientsModule },
  pillars: { component: Pillars },
  'pull-quote': {
    component: PullQuote,
    wrapperProps: { width: 'normal' },
  },
  'pull-quote-blob': {
    component: PullQuoteBlob,
    sectionProps: { bg: 'gray' },
    wrapperProps: { width: 'wide', gutter: false },
    componentProps: { blobColor: 'red', cardColor: 'white' },
  },
  'pull-quote-full': {
    component: PullQuoteFull,
    sectionProps: { bg: 'green' },
    wrapperProps: { width: 'wide', gutter: false, rounded: false },
    componentProps: { cardColor: 'white' },
  },
  'shop-by': { component: ShopBy },
  'social-contacts': { component: SocialContact },
  'text-body': { component: TextBody },
  'text-big': { component: TextModule },
  'text-with-blobs': {
    component: TextModule,
    sectionProps: { gutter: true },
    wrapperProps: { width: 'full', bg: 'lime' },
    componentProps: { centered: true, showShapes: true },
  },
  'value-prop-grid': {
    component: ValuePropGrid,
    sectionProps: { gutter: true },
    wrapperProps: { width: 'normal', gutter: true, bg: 'white' },
  },
  values: { component: Values },
  'video-carousel': {
    component: VideoCarousel,
    sectionProps: { bg: 'green', color: 'white' },
  },
  'video-single': { component: VideoSingle },
};

export const PageSection: React.FC<PageSectionProps> = ({
  section,
  sectionProps = {},
  wrapperProps = {},
  componentProps = {},
}) => {
  if (!section?.sectionType) {
    return null;
  }
  const componentData = ComponentsMap[section.sectionType.slug];

  if (!componentData) {
    return null;
  }

  const sectionPropsFromCMS = {
    ...(section.sectionGutter && { gutter: section.sectionGutter }),
    ...(section.sectionBgColor && { bg: section.sectionBgColor }),
    ...(section.sectionTextColor && { color: section.sectionTextColor }),
  };

  const wrapperPropsFromCMS = {
    ...(section.cardWidth && { width: section.cardWidth }),
    ...(section.cardBgColor && { bg: section.cardBgColor }),
  };

  return (
    <Section
      className={[section.sectionType.slug, section.slug].join(' ')}
      {...componentData.sectionProps}
      {...sectionProps}
      {...sectionPropsFromCMS}
    >
      <Wrapper
        {...componentData.wrapperProps}
        {...wrapperProps}
        {...wrapperPropsFromCMS}
      >
        <componentData.component
          {...componentData.componentProps}
          {...componentProps}
          section={section}
        />
      </Wrapper>
    </Section>
  );
};

type ComponentsExtraProps = {
  sectionProps?: SectionProps;
  wrapperProps?: WrapperProps;
  componentProps?: any;
};

interface ComponentData extends ComponentsExtraProps {
  component: any;
}

type ComponentMapData = Partial<Record<SectionType, ComponentData>>;

interface PageSectionProps {
  section: ContentfulPageSection;
  sectionProps?: SectionProps;
  wrapperProps?: WrapperProps;
  componentProps?: any;
}

export type ComponentMapExtraData = Partial<
  Record<SectionType, ComponentsExtraProps>
>;

export type InstanceMapExtraData = Partial<
  Record<string, ComponentsExtraProps>
>;
