import React, { useRef } from 'react';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import SwiperType, { Autoplay, Pagination, Virtual, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/virtual';
import 'swiper/css/pagination';
import { useMediaQuery, useWindowEvent } from '@mantine/hooks';

import { GatsbyImageData, ThemeColor } from '~/types/types';
import {
  StyledAutoCarousel,
  StyledAutoCarouselQuoteCard,
  StyledAutoCarouselMobileCard,
} from './AutoCarousel.style';
import { CtaParsed } from '../Generic/Cta/CtaParsed';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';

export const AutoCarousel: React.FC<AutoCarouselProps> = ({
  section,
  slidesPerView = 3,
}) => {
  const { title, description, media, cta } = section;
  const swiperRef = useRef<SwiperType>(null);
  useWindowEvent('resize', () => {
    swiperRef.current?.autoplay.stop();
    setTimeout(() => {
      swiperRef.current.autoplay.start();
    }, 200);
  })

  const isMobile = useMediaQuery('(max-width: 767px)');

  const SWIPER_OPTS: SwiperOptions = {
    modules: isMobile ? [Autoplay, Pagination, Virtual] : [Autoplay],
    loop: true,
    autoplay: {
      delay: isMobile ? 5000 : 0,
      disableOnInteraction: false,
    },
    pagination: isMobile,
    preventClicksPropagation: false,
    touchStartPreventDefault: false,
    slidesPerView: isMobile ? 1 : slidesPerView,
    speed: isMobile ? 1000 : 7000,
    spaceBetween: 40,
    updateOnWindowResize: true,
  };

  const quoteColors = ['green', 'orange', 'red'] as ThemeColor[];
  const quoteColorFromIndex = (i: number) =>
    quoteColors[i % quoteColors.length];

  return (
    <StyledAutoCarousel>
      <div className="section__text">
        <ShowOnScroll>
          <span className="section__label">{title}</span>
        </ShowOnScroll>
        <ShowOnScroll
          delay={200}
          className="section__content"
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        />
      </div>

      {media?.length && (
        <Swiper {...SWIPER_OPTS} onSwiper={ref => swiperRef.current = ref} className="section__carousel">
          {media.map((card, i) => {
            if (isMobile) {
              return (
                <SwiperSlide key={`${card.label}-mobile`}>
                  <StyledAutoCarouselMobileCard color={quoteColorFromIndex(i)}>
                    {card.media && (
                      <GatsbyImage
                        image={card.media.gatsbyImageData as GatsbyImageData}
                        alt={card.media.title}
                        className="section__image"
                      />
                    )}
                    {(card.description || card.label) && (
                      <div className="card__text">
                        <div
                          className="card__quote"
                          dangerouslySetInnerHTML={{
                            __html: card?.description?.childMarkdownRemark.html,
                          }}
                        />
                        <p className="card__label">{card.label}</p>
                      </div>
                    )}
                  </StyledAutoCarouselMobileCard>
                </SwiperSlide>
              );
            }

            return (
              <React.Fragment key={`${card.title}_${i}`}>
                {card.description && (
                  <SwiperSlide key={`${card.title}-text`}>
                    <ShowOnScroll delay={100 * i}>
                      <StyledAutoCarouselQuoteCard
                        color={quoteColorFromIndex(i)}
                      >
                        <div
                          className="card__quote"
                          dangerouslySetInnerHTML={{
                            __html: card.description?.childMarkdownRemark.html,
                          }}
                        />
                        {card.label && (
                          <p className="card__label">{card.label}</p>
                        )}
                      </StyledAutoCarouselQuoteCard>
                    </ShowOnScroll>
                  </SwiperSlide>
                )}
                {card.media && (
                  <SwiperSlide key={`${card.title}-image`}>
                    <ShowOnScroll delay={300 * i}>
                      <GatsbyImage
                        image={card.media.gatsbyImageData as GatsbyImageData}
                        alt={card.media.title}
                      />
                    </ShowOnScroll>
                  </SwiperSlide>
                )}
              </React.Fragment>
            );
          })}
        </Swiper>
      )}

      {cta && (
        <ShowOnScroll delay={200} className="section__cta">
          <CtaParsed raw={section.cta.raw} variant="secondary" size="large" />
        </ShowOnScroll>
      )}
    </StyledAutoCarousel>
  );
};

interface AutoCarouselProps {
  section: {
    title: string;
    description?: MarkdownData;
    cta?: {
      raw: string;
    };
    media?: {
      title: string;
      label?: string;
      description?: MarkdownData;
      media?: any;
    }[];
  };
  slidesPerView?: number;
}
