import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { StyledProtocolCard } from './ProtocolCard.style';
import { Protocol } from '../../types/types';

export const ProtocolCard: React.FC<ProtocolCardProps> = ({ protocol }) => {
  return (
    <StyledProtocolCard>
      <Link to={`/guided-protocols/${protocol.slug}`}>
        <GatsbyImage
          className="protocol-card--thumbnail"
          image={protocol.thumbnailImage.gatsbyImageData}
          alt={protocol.title}
        />
        {protocol?.author && <div className="protocol-card--cardAuthor">
          {protocol?.author?.image?.gatsbyImageData && <GatsbyImage
            className="protocol-card--authorAvatar"
            image={protocol.author.image.gatsbyImageData}
            alt={protocol.author.image.title}
          />}
          <span>Curated by {protocol.author.name}</span>
        </div>}
        <div className="protocol-card--cardTitle">{protocol.title}</div>
      </Link>
    </StyledProtocolCard>
  );
};

interface ProtocolCardProps {
  protocol: Protocol;
}
