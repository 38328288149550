import React from 'react';
import useUser from "~/hooks/components/use-user";
import { ContentCard, ContentfulPageSection } from '../../types/types';
import { Cta } from '../Generic/Cta/Cta';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { StyledValuePropGrid } from './ValuePropGrid.style';

export const ValuePropGrid: React.FC<ValuePropGridProps> = ({
  section,
  maxColumns,
  onCtaClick
}) => {
  const { title, media: contentCards, cta } = section;
  const { content } = cta ? JSON.parse(cta?.raw) : { content: undefined };
  const [, ctaInfo] = content?.[0]?.content || [];
  const { user } = useUser();
  const isAmbassadorUser = user?.data?.roles?.includes?.('ambassador');
  
  return (
    <StyledValuePropGrid maxColumns={maxColumns}>
      {title && (
        <ShowOnScroll>
          <h2>{title}</h2>
        </ShowOnScroll>
      )}
      <div className="grid">
        {contentCards.map(
          ({ media, description, label, title }: ContentCard, idx: number) => (
            <div className="grid-card" key={`${title}_${idx}`}>
              <ShowOnScroll delay={idx * 50}>
                <img
                  src={media.file.url}
                  width={media.file.details.image.width}
                  height={media.file.details.image.height}
                />
              </ShowOnScroll>
              <ShowOnScroll delay={idx * 50}>
                <p className="section-label">{label}</p>
              </ShowOnScroll>
              <ShowOnScroll delay={idx * 100}>
                <div
                  className="grid-card--description"
                  dangerouslySetInnerHTML={{
                    __html: description.childMarkdownRemark.html,
                  }}
                />
              </ShowOnScroll>
            </div>
          )
        )}
      </div>
      {ctaInfo && !isAmbassadorUser && (
        <ShowOnScroll delay={(contentCards.length + 1) * 50}>
          <Cta href={ctaInfo.data.uri} onClick={e => onCtaClick?.()}>{ctaInfo.content[0].value}</Cta>
        </ShowOnScroll>
      )}
    </StyledValuePropGrid>
  );
};

export interface ValuePropGridProps {
  section: ContentfulPageSection;
  maxColumns?: number;
  onCtaClick?: () => any;
}
