import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Article, ContentfulPageSection } from '~/types/types';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { ArticleCard } from '../ArticleCard/ArticleCard';

import { StyledFeaturedArticles } from './FeaturedArticles.style';

export const FeaturedArticles: React.FC<FeaturedArticlesProps> = ({
  section,
}) => {
  const data = useStaticQuery(graphql`
    query FeaturedArticles {
      contentfulFeaturedArticles {
        featuredArticles {
          ...ArticleCardFragment
        }
      }
    }
  `);

  const { featuredArticles }: { featuredArticles: Article[] } =
    data.contentfulFeaturedArticles;

  const articles = (section.media as Article[]) || featuredArticles;

  return (
    <StyledFeaturedArticles>
      {section.description && (
        <ShowOnScroll
          delay={200}
          className="text__content"
          dangerouslySetInnerHTML={{
            __html: section.description.childMarkdownRemark.html,
          }}
        />
      )}
      <div className="articles--grid">
        {articles.map((a, i) => {
          return <ArticleCard article={a} key={a.slug} />;
        })}
      </div>
    </StyledFeaturedArticles>
  );
};

interface FeaturedArticlesProps {
  section: ContentfulPageSection;
}
