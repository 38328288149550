import React from 'react';
import { useTheme } from 'styled-components';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { StyledValuesModule } from './Values.style';

import Shape5 from '~/assets/images/shapes/shape5.svg';
import Shape6 from '~/assets/images/shapes/shape6.svg';
import Shape7 from '~/assets/images/shapes/shape7.svg';

import { ContentCard, ContentfulPageSection, ThemeColor } from '~/types/types';
import { Cta } from '../Generic/Cta/Cta';

const shapeColors = ['red', 'orange', 'green'] as ThemeColor[];

const ShapeFromIndex = ({ i }: { i: number }) => {
  const theme = useTheme();
  const mod = i % shapeColors.length;
  const color = theme.colors[shapeColors[mod]];

  if (mod === 0) {
    return <Shape5 fill={color} />;
  }

  if (mod === 1) {
    return <Shape7 fill={color} />;
  }

  return <Shape6 fill={color} />;
};

export const Values: React.FC<TextModuleProps> = ({
  section: { description, media, cta },
}) => {
  const [{ content: ctaContent }] = cta?.raw
    ? JSON.parse(cta.raw)?.content
    : [{ content: null }];
  const { content, data } = ctaContent?.[1] || { content: '', data: {} };
  return (
    <StyledValuesModule>
      {description && (
        <ShowOnScroll
          delay={200}
          className="values__description"
          dangerouslySetInnerHTML={{
            __html: description.childMarkdownRemark.html,
          }}
        />
      )}

      {media && (
        <div className="values__media">
          {(media as ContentCard[]).map((card, i) => {
            return (
              <ShowOnScroll
                delay={200 + 50 * i}
                key={i}
                className="values__mediaCard"
              >
                <div className="values__mediaShape">
                  <ShapeFromIndex i={i} />
                  <div className="values__mediaText">
                    <p dangerouslySetInnerHTML={{ __html: card.label }} />
                  </div>
                </div>
                <div
                  className="values__mediaContent"
                  dangerouslySetInnerHTML={{
                    __html: card.description.childMarkdownRemark.html,
                  }}
                />
              </ShowOnScroll>
            );
          })}
        </div>
      )}

      {cta && (
        <ShowOnScroll>
          <Cta className="values__cta" href={data.uri}>
            {content[0].value}
          </Cta>
        </ShowOnScroll>
      )}
    </StyledValuesModule>
  );
};

interface TextModuleProps {
  section: ContentfulPageSection;
}
