import React from 'react';
import { Cta, CtaProps } from './Cta';

export const CtaParsed: React.FC<CtaParsedProps> = ({ raw, ...props }) => {
  const [, hyperLink] = JSON.parse(raw).content?.[0].content;
  const { data, content } = hyperLink;
  const ctaText = content?.[0].value;
  const ctaHref = data.uri;

  return (
    <Cta href={ctaHref} {...props}>
      {ctaText}
    </Cta>
  );
};

interface CtaParsedProps extends Omit<CtaProps, 'href' | 'children'> {
  raw: string;
}
