import React from 'react';
import { ContentfulPageSection } from '~/types/types';

import ShowOnScroll from '~/components/Generic/ShowOnScroll/ShowOnScroll';
import { StyledVideoSection } from './VideoSingle.style';

export const VideoSingle: React.FC<VideoSingleProps> = ({ section }) => {
  return (
    <StyledVideoSection>
      <ShowOnScroll>
        <h2>{section.title}</h2>
      </ShowOnScroll>
      <ShowOnScroll
        delay={200}
        className="content"
        dangerouslySetInnerHTML={{
          __html: section.description.childMarkdownRemark.html,
        }}
      />
    </StyledVideoSection>
  );
};

interface VideoSingleProps {
  section: ContentfulPageSection;
}
