import styled from 'styled-components';
import { desktopHover, pxtorem } from '~/utils/tools';

export const StyledAccordion = styled.div``

export const StyledAccordionItem = styled.div`
  --color: var(--black);
  --activeColor: var(--darkGray);
  --borderColor: #13131333;

  border-top: 1px solid var(--borderColor);

  &:last-child {
    border-bottom: 1px solid var(--borderColor);
  }

  .accordionItem {
    color: var(--color);

    &__trigger {
      transition: color .25s;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;
      font-size: ${ pxtorem(16) };
      font-weight: 400;
      padding: ${pxtorem(20)} 0;
      cursor: pointer;
      appearance: none;
      border: none;
      background: transparent;
      text-align: left;
      color: var(--color);

      & .indicator {
        --size: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 var(--size);
        border: 1px solid var(--black);
        border-radius: 50%;
        margin-left: calc(var(--size) / 2);
        width: var(--size);
        height: var(--size);
        transition: transform .45s cubic-bezier(.55,0,.1,1);
        pointer-events: none;
        will-change: transform;

        & svg {
          transform: translate(0, 1px);
        }
      }
      
      ${desktopHover(`color: var(--activeColor);`)}
      
      &--opened {
        color: var(--activeColor);

        & .indicator {
          transform: rotate(180deg);
        }
      }
    }

    &__content {
      height: 0;
      overflow: hidden;
      color: var(--color);

      & p,
      & ul,
      & ol {
        margin: 0;

        & + p,
        & + ul,
        & + ol {
          margin-top: 1em;
        }
      }

      & ul,
      & ol {
        padding-left: 1.5em;
      }
    }

    &__subHolder {
      max-height: 70vh;
      overflow: auto;
      padding-bottom: 16px;
    }
  }

  @media (min-width: 768px) {
    .accordionItem {
      &__trigger {
        font-size: ${ pxtorem(20) };

        & .indicator {
          margin-right: 50px;
        }
      }

      &__content {
        padding-right: 100px;
      }
    }
  }
`