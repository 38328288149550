import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { ContentCard, ContentfulPageSection } from '../../types/types';

import { CtaParsed } from '../Generic/Cta/CtaParsed';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { StyledPullQuote } from './PullQuote.style';

export const PullQuote: React.FC<PullQuoteProps> = ({ section }) => {
  const { title, description } = section;
  const media = section.media?.[0] as ContentCard;

  if (!media) { return null; }
  return (
    <StyledPullQuote>
      <div className="pull-quote__content">
        <ShowOnScroll>
          <span className="pull-quote__title">{title}</span>
          <div
            className="pull-quote__text"
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
          {section.cta && <CtaParsed raw={section.cta.raw} />}
        </ShowOnScroll>
      </div>
      <div className="pull-quote__image">
        <ShowOnScroll>
          {media?.media && (
            <GatsbyImage
              image={media.media.gatsbyImageData}
              alt={media.media.title}
            />
          )}
        </ShowOnScroll>
      </div>
    </StyledPullQuote>
  );
};

interface PullQuoteProps {
  section: ContentfulPageSection;
}
