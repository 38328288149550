import { useMediaQuery } from '@mantine/hooks';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Pagination, SwiperOptions, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ContentCard, ContentfulPageSection } from '../../types/types';
import {
  StyledIngredientModuleCard,
  StyledIngredientsModuleDesktop,
  StyledIngredientsModuleMobile,
} from './IngredientsModule.style';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';

// The Ingredients Module found on the Learn Page
const SWIPER_OPTS: SwiperOptions = {
  slidesPerView: 1,
  modules: [Autoplay, Pagination],
  pagination: {
    clickable: true,
  },
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
};

export const IngredientsModule: React.FC<IngredientsModuleProps> = ({
  section,
}) => {
  const isCarousel = useMediaQuery('(max-width: 1150px)');

  if (isCarousel) {
    return (
      <StyledIngredientsModuleMobile>
        <ShowOnScroll>
          <p className="section-title">{section.title}</p>
        </ShowOnScroll>
        <ShowOnScroll
          className="section-subtitle"
          dangerouslySetInnerHTML={{
            __html: section.description.childMarkdownRemark.html,
          }}
        />
        <Swiper {...SWIPER_OPTS} className="carousel">
          {section.media.map((media: ContentCard) => {
            return (
              <SwiperSlide key={media.id}>
                <IngredientModuleCard data={media} layout="mobile" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </StyledIngredientsModuleMobile>
    );
  }

  return (
    <StyledIngredientsModuleDesktop>
      <ShowOnScroll>
        <p className="section-title">{section.title}</p>
      </ShowOnScroll>
      <div
        className="section-subtitle"
        dangerouslySetInnerHTML={{
          __html: section.description.childMarkdownRemark.html,
        }}
      />
      <div className="grid">
        {section.media.map((media: ContentCard) => {
          return <IngredientModuleCard data={media} key={media.id} />;
        })}
      </div>
    </StyledIngredientsModuleDesktop>
  );
};

const IngredientModuleCard: React.FC<IngredientModuleCardProps> = ({
  data,
  layout = 'desktop',
}) => {
  const { media, label, description, title } = data;
  const mobileHtmlStr = description.childMarkdownRemark.html.split('<hr>');
  return (
    <StyledIngredientModuleCard>
      <div className="container">
        <ShowOnScroll className="img-container">
          <GatsbyImage image={media.gatsbyImageData} alt={media.title} />
        </ShowOnScroll>
        <div className={`content ${layout === 'mobile' ? 'mobile' : ''}`}>
          <div>
            <ShowOnScroll>
              <p className="label">{title}</p>
            </ShowOnScroll>
            {layout === 'desktop' && (
              <ShowOnScroll>
                <p>{label}</p>
              </ShowOnScroll>
            )}
          </div>
          <ShowOnScroll
            className="description"
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
      {layout === 'mobile' && (
        <div className="mobile-content">
          <hr />
          <ShowOnScroll
            className="description"
            dangerouslySetInnerHTML={{ __html: mobileHtmlStr[1] }}
          />
          <ShowOnScroll>
            <p className="label">{label}</p>
          </ShowOnScroll>
          <hr />
        </div>
      )}
    </StyledIngredientModuleCard>
  );
};
interface IngredientsModuleProps {
  section: ContentfulPageSection;
}

interface IngredientModuleCardProps {
  data: ContentCard;
  layout?: 'mobile' | 'desktop';
}
