import styled, { css } from 'styled-components';
import { pxtorem } from '~/utils/tools';

const SocialContactBaseCSS = css<StyledSocialContactProps>`
  position: relative;
  text-align: center;
  color: var(--color-text-primary);

  padding: 160px 0;

  @media (min-width: 768px) {
    padding: 160px 0 100px;
  }

  ${props =>
    props.customBgMedia &&
    css`
      background: url(${props.customBgMedia}) no-repeat center center;
      background-size: cover;
      background-position: left;
      border-radius: var(--radius);

      @media (min-width: 768px) {
        background-position: center;
        position: relative;
        &:after {
          content: '';
          display: block;
          padding-top:35%;
        }
      }

      .text-container {
        color: var(--white);

        a {
          color: var(--white);
        }

        @media (min-width: 768px) {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
        }
      }
    `}

  h2 {
    font-weight: 300;
    font-size: ${pxtorem(40)};
    line-height: 1;
    letter-spacing: -0.01em;
    margin: 0 1em 1em;

    @media (min-width: 768px) {
      font-size: ${pxtorem(60)};
      line-height: 0.96666;
      max-width: 9em;
      margin: 0 auto 0.75em;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    margin: 0 20px;
    padding: 0;
    list-style: none;
    gap: 2em;

    .cta__label {
      font-weight: 500;
      font-size: ${pxtorem(16)};

      @media (min-width: 768px) {
        font-size: ${pxtorem(24)};
      }
    }
  }

  & figure {
    margin: 60px auto 0;
    max-width: 800px;

    @media (min-width: 768px) {
      margin-top: 20px;
    }
    & .gatsby-image-wrapper {
      mix-blend-mode: darken;
    }
  }
`;

export const StyledSocialContact = styled.div`
  ${SocialContactBaseCSS}
`;

interface StyledSocialContactProps {
  customBgMedia?: string;
}
