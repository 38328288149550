import styled from 'styled-components';
import { desktopHover, pxtorem } from '../../utils/tools';

export const StyledShopBy = styled.div`
  --swiper-theme-color: ${props => props.theme.colors.black};
  --swiper-pagination-bullet-size: ${pxtorem(4)};

  padding-inline: min(5%, ${pxtorem(68)});
  margin: ${pxtorem(65)} 0;

  .shopby__text {
    text-align: center;

    h1 {
      margin: 0;
      font-size: ${pxtorem(40)};

      em {
        font-size: ${pxtorem(35)};
      }

      & + div {
        margin-top: ${pxtorem(10)};
      }

      @media (min-width: 768px) {
        font-size: ${pxtorem(60)};
        em {
          font-size: ${pxtorem(52)};
        }
      }
    }

    p {
      font-size: ${pxtorem(16)};
      line-height: 1;
      font-weight: 400;
      margin: 0;

      @media (min-width: 768px) {
        font-size: ${pxtorem(20)};
      }
    }
  }

  .shopby__cards {
    display: flex;
    justify-content: space-between;
    margin-top: ${pxtorem(40)};
  }

  .shopby__cardLink {
    text-decoration: none;
    color: var(--black);
    img {
      transition: transform 0.4s var(--cubic-ease);
    }
    transition: color 0.2s linear;
    ${desktopHover(`
      img {
        transform: scale(1.05);
      }
      color: var(--green);
    `)}
  }

  .shopby__card {
    text-align: center;

    figure {
      margin: 0;
    }

    & + .shopby__card {
      margin-left: ${pxtorem(32)};
    }

    figcaption {
      @media (min-width: 768px) {
        font-size: ${pxtorem(20)};
        font-weight: 400;
      }
      margin-top: ${pxtorem(15)};
    }
  }

  .shopby__cardImage {
    border-radius: var(--radius);
    overflow: hidden;
    .gatsby-image-wrapper {
      position: relative;
      width: 100%;
      &:before {
        content: '';
        display: block;
        padding-top: 124%;
      }
      img, >div {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        object-fit: cover;
      }
    }
  }

  .shopby__carousel {
    overflow: visible;
    margin-top: ${pxtorem(30)};
  }
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -30px;
  }

  @media (min-width: 1920px) {
    padding-inline: 0;
  }
`;
