import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { StyledAccordionItem } from './Accordion.styled';
import ChevronIcon from '~/assets/images/icons/chevron.svg'

export const AccordionItem: React.FC<React.PropsWithChildren<AccordionProps>> = ({ children, title, open }) => {
  //#region Hooks / Lifecycles
  const itemRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (open) {
      gsap.set(itemRef.current.querySelector('.accordionItem__content'), {
        height: 'auto'
      });
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions

  const onTriggerClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    const target: HTMLElement = e.target as HTMLElement;
    if (target.closest('.accordionItem__content')) {
      // Click inside of the panel
      return;
    }

    const parent: HTMLElement = target.closest('.accordionItem');
    const dropdown: HTMLElement = parent?.querySelector('.accordionItem__content');

    if (!parent || !dropdown) {
      return;
    }

    const openedDropdown: NodeListOf<HTMLElement> = itemRef?.current.closest('.accordion')?.querySelectorAll('.accordionItem__content--opened');

    gsap.killTweensOf([openedDropdown, dropdown]);

    if (dropdown.classList.contains('accordionItem__content--opened')) {
      dropdown.classList.remove('accordionItem__content--opened');
      target.classList.remove('accordionItem__trigger--opened');
      gsap.to(dropdown, {
        height: '0px',
        duration: 1,
        ease: 'power4.out'
      });
      return;
    }

    for (let i = 0; i < openedDropdown.length; i++) {
      openedDropdown[i].classList.remove('accordionItem__content--opened');
      openedDropdown[i].previousElementSibling?.classList.remove('accordionItem__trigger--opened');
    }

    gsap.to(openedDropdown, {
      height: '0px',
      duration: 1,
      ease: 'power4.out'
    })

    dropdown.classList.add('accordionItem__content--opened');
    target.classList.add('accordionItem__trigger--opened');

    gsap.to(dropdown, {
      height: 'auto',
      duration: 1,
      ease: 'power4.out'
    });
  }
  //#endregion

  //#region Templating
  return (
    <StyledAccordionItem className="accordionItem" ref={itemRef}>
      <button
        type="button"
        onClick={onTriggerClick}
        className={`accordionItem__trigger ${open ? 'accordionItem__trigger--opened' : ''}`}>
        <span className="label">{ title }</span>
        <span className="indicator">
          <ChevronIcon />
        </span>
      </button>
      <div className={`accordionItem__content ${open ? 'accordionItem__content--opened' : ''}`}>
        <div className="accordionItem__subHolder">
          {children}
        </div>
      </div>
    </StyledAccordionItem>
  )
  //#endregion
}

interface AccordionProps {
  title: string;
  open?: boolean;
}