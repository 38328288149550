import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledPillars = styled.div`
  padding: ${pxtorem(20)} 0;
  text-align: center;

  @media (min-width: 768px) {
    padding: ${pxtorem(50)} 0 ${pxtorem(73)};
  }

  h2 {
    @media (max-width: 767px) {
      width: 80%;
      margin-inline: auto;
    }
  }

  .pillars-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: ${pxtorem(40)};
    justify-items: center;
    margin-inline: auto;

    @media (min-width: 768px) {
      width: 80%;
      grid-template-columns: repeat(2, 1fr);
    }

    .pillar {
      display: flex;
      align-items: center;
      gap: ${pxtorem(24)};
      justify-content: center;

      p {
        font: 400 ${pxtorem(16)} / 117% var(--primaryFont);
        max-width: 35ch;
        text-align: left;
        margin: 0;
      }

      @media (max-width: 1200px) {
        width: 90%;
      }
    }
  }
`;
