import styled from 'styled-components/macro';

import { desktopHover, pxtorem } from '~/utils/tools';
import arrow from '~/styles/img/product-testimonial-arrow.svg';

export const StyledFeaturedProducts = styled.div`
  --swiper-theme-color: ${props => props.theme.colors.black};
  --swiper-pagination-bullet-size: ${pxtorem(4)};

  position: relative;
  padding: ${pxtorem(109)} 0;
  padding-top: ${pxtorem(60)};

  & .background {
    position: absolute;
    inset: 0;
    z-index: 1;
    overflow: hidden;

    & .gatsby-image-wrapper {
      height: 100%;
      display: block;
    }
  }

  .text-container {
    position: relative;
    margin-bottom: ${pxtorem(40)};
    z-index: 2;
  }

  .text__title {
    width: 100%;
    text-align: center;
    font-size: ${pxtorem(40)};
    font-weight: 300;
    line-height: 1;
    margin-bottom: ${pxtorem(16)};

    @media (min-width: 768px) {
      font-size: ${pxtorem(60)};
    }
  }

  .text__content p {
    text-align: center;
    margin-bottom: ${pxtorem(40)};
  }

  .slider-container {
    margin-left: var(--gutter);
    margin-right: var(--gutter);
    z-index: 2;
  }
  .swiper {
    overflow: visible;
    max-width: 360px;
  }

  .featuredProducts-prev,
  .featuredProducts-next {
    flex: 0 0 40px;
    height: 40px;
    mask: url(${arrow}) center no-repeat;
    mask-size: contain;
    background: var(--black);
    transition: background 250ms, opacity 250ms, visibility 0ms;
    cursor: pointer;
    z-index: 2;

    ${desktopHover(`background: var(--coral);`)}

    @media (max-width: 767px) {
      display: none;
    }
  }

  .featuredProducts-prev {
    transform: scale(-1, 1);
  }

  .swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-slide {
    max-width: 360px;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -30px;
  }

  @media (min-width: 768px) {
    .slider-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-left: var(--gutter);
      margin-right: var(--gutter);
    }

    .swiper {
      overflow: hidden;
      margin: 0;
      max-width: calc((360px + 20px) * 2);
    }
    .swiper-pagination {
      display: none;
    }
  }

  @media (min-width: 1280px) {
    .slider-container {
      gap: 40px;
    }
    // three slides + space for arrows
    .swiper {
      max-width: calc((360px + 20px) * 3);
    }
  }
`;
