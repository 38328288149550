import { useMediaQuery } from '@mantine/hooks';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import BgBlob from '~/assets/images/shapes/shape-lg.svg';
import { OurStoryProps } from '../../pages/our-story';
import { GatsbyImageData, ThemeColor } from '../../types/types';
import { Cta } from '../Generic/Cta/Cta';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { StyledPullQuoteBlob } from './PullQuoteBlob.style';

export const PullQuoteBlob: React.FC<PullQuoteBlobProps> = ({
  section,
  blobColor,
  cardColor,
  onCtaClick,
}) => {
  const isHorizontalLayout = useMediaQuery('(min-width: 1280px)');
  const [, hyperLink] = JSON.parse(section.cta.raw).content?.[0].content;
  const [{ label, description, media }] = section.media;
  const { data, content } = hyperLink;
  const ctaText = content?.[0].value;
  const ctaHref = data.uri;
  return (
    <StyledPullQuoteBlob blobColor={blobColor} cardColor={cardColor}>
      <BgBlob />
      <div className="flex-between">
        <ShowOnScroll className="pull-quote-blob" passive={isHorizontalLayout}>
          <ShowOnScroll>
            <span className="section-label">{label}</span>
          </ShowOnScroll>
          <ShowOnScroll
            delay={150}
            className="pull-quote-blob--content"
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
          <ShowOnScroll delay={300}>
            <Cta
              className="pull-quote-blob__cta"
              href={ctaHref}
              onClick={e => onCtaClick?.()}
            >
              {ctaText}
            </Cta>
          </ShowOnScroll>
        </ShowOnScroll>
        <ShowOnScroll className="img-container" passive={isHorizontalLayout}>
          <GatsbyImage
            image={media.gatsbyImageData as GatsbyImageData}
            alt={label}
            className="section__image"
          />
        </ShowOnScroll>
      </div>
    </StyledPullQuoteBlob>
  );
};

export interface PullQuoteBlobProps {
  section: OurStoryProps['data']['page']['sections'][0];
  blobColor: ThemeColor;
  cardColor: ThemeColor;
  onCtaClick?: () => any;
}
