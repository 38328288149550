import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { ContentCard, ContentfulPageSection } from '../../types/types';
import { StyledImageBanner } from './ImageBanner.style';

export const ImageBanner: React.FC<ImageBannerProps> = ({ section }) => {
  const media = section.media?.[0] as ContentCard;
  if (!media?.media?.gatsbyImageData) {
    return null;
  }
  return (
    <StyledImageBanner>
      <GatsbyImage
        image={media.media.gatsbyImageData}
        alt={media.title}
        className="bg-img"
      />
    </StyledImageBanner>
  );
};

interface ImageBannerProps {
  section: ContentfulPageSection;
}
