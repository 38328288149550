import styled from 'styled-components';
import { Wrapper } from '~/styles/Wrapper.styled';

import { pxtorem } from '../../utils/tools';
import { ThemeColor } from '~/types/types';

export const StyledAutoCarousel = styled.div`
  --swiper-theme-color: ${props => props.theme.colors.black};
  --swiper-pagination-bullet-size: ${pxtorem(4)};

  padding: ${pxtorem(40)} 0;

  @media (min-width: 768px) {
    padding: ${pxtorem(130)} 0;
  }

  .section__text {
    text-align: center;
    width: 80%;
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 60%;
    }
  }

  .section__label {
    font-size: ${pxtorem(12)};
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: ${pxtorem(16)};
    }
  }

  .section__content {
    h1 {
      font-size: clamp(${pxtorem(40)}, 5vw, ${pxtorem(60)});
      margin: ${pxtorem(10)} 0;
      em {
        font-size: clamp(${pxtorem(35)}, 3.5vw, ${pxtorem(52)});
      }

      @media (min-width: 768px) {
        margin: ${pxtorem(20)} 0;
      }
    }

    p {
      max-width: 460px;
      margin: ${pxtorem(20)} auto;
      font-size: ${pxtorem(16)};
      font-weight: 400;

      @media (min-width: 768px) {
        font-size: ${pxtorem(20)};
      }
    }
  }

  .section__carousel {
    img {
      border-radius: var(--radius);
      overflow: hidden;
    }
  }

  .swiper-ios {
    margin-top: -2rem;
  }

  .section__cta {
    text-align: center;
    margin-top: ${pxtorem(40)};

    @media (max-width: 767px) {
      a {
        font-size: ${pxtorem(16)};
      }
    }
  }

  .swiper-wrapper {
    align-items: center;
    margin-top: ${pxtorem(40)};

    @media (min-width: 768px) {
      transition-timing-function: linear;
      pointer-events: none;
    }
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
  }
`;

export const StyledAutoCarouselQuoteCard = styled.div<{ color?: ThemeColor }>`
  background: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.gray};
  color: ${props => props.theme.colors.white};
  border-radius: var(--radius);
  padding: ${pxtorem(20)};

  .card__quote p {
    font-size: ${pxtorem(20)};
    font-weight: 400;
    margin: 0;
  }

  .card__label {
    margin: ${pxtorem(40)} 0 0;
    text-align: right;
    font-size: ${pxtorem(24)};
    font-weight: 300;
    font-family: var(--secondaryFont);
    font-style: italic;
  }
`;

export const StyledAutoCarouselMobileCard = styled.div<{ color?: ThemeColor }>`
  max-width: 70%;
  margin: 0 auto;

  img {
    border-radius: var(--radius);
  }

  .card__text {
    background: ${props =>
      props.color ? props.theme.colors[props.color] : props.theme.colors.gray};
    color: ${props => props.theme.colors.white};
    border-radius: var(--radius);
    padding: ${pxtorem(20)};
    transform: translateY(calc(var(--radius) * -2));
  }

  .card__quote p {
    font-size: ${pxtorem(16)};
    font-weight: 400;
    margin: 0;
  }

  .card__label {
    margin: ${pxtorem(40)} 0 0;
    text-align: right;
    font-size: ${pxtorem(16)};
    font-weight: 300;
    font-family: var(--secondaryFont);
    font-style: italic;
  }
`;
