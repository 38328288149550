import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';
import { StyledLink } from '../Generic/Cta/Cta.style';

export const StyledPullQuote = styled.div`
  margin-block: calc(var(--gutter) * 4);

  .pull-quote {
    &__title {
      font-size: ${pxtorem(28)};
      margin-bottom: ${pxtorem(6)};
    }

    &__image {
      margin-block: calc(var(--gutter) * 2);
    }
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: calc(var(--gutter) * 2);

    .pull-quote {
      &__content,
      &__image {
        flex: 1 1 50%;
      }

      &__title {
        font-size: ${pxtorem(60)};
        margin-bottom: ${pxtorem(6)};
      }

      &__text p {
        font-size: ${pxtorem(20)};
        line-height: 1.18;
        margin-bottom: calc(var(--gutter) * 1.5);
      }

      &__image {
        margin-block: 0;
      }
    }
  }
`;
