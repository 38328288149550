import styled from 'styled-components';

export const StyledImageGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--gutter);

  @media (min-width: 768px) {
    row-gap: calc(var(--gutter) * 4);

    .grid-image {
      flex: 0 0 50%;
      padding-inline: var(--gutter);
      display: flex;
      margin-inline: auto;

      // scale down each 1st and 4th images
      &:nth-child(4n + 1),
      &:nth-child(4n) {
        max-width: 40%;
      }
    }
  }
`;
