import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import ShowOnScroll from '~/components/Generic/ShowOnScroll/ShowOnScroll';
import { ContentCard, ContentfulPageSection } from '../../types/types';
import { StyledImageGrid } from './ImageGrid.style';

export const ImageGrid: React.FC<ImageGridProps> = ({ section }) => {
  const medias = section.media as ContentCard[];
  return (
    <StyledImageGrid>
      {medias.map((media: ContentCard, i) => (
        <ShowOnScroll
          key={`imagegrid-${section.slug}-${i}`}
          delay={100 * i}
          className="grid-image"
        >
          <GatsbyImage
            image={media.media.gatsbyImageData}
            objectFit="contain"
            alt={media.title}
          />
        </ShowOnScroll>
      ))}
    </StyledImageGrid>
  );
};

interface ImageGridProps {
  section: ContentfulPageSection;
}
