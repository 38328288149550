import styled from 'styled-components';
import { ThemeColor } from '../../types/types';
import { pxtorem } from '../../utils/tools';
import { Wrapper } from '~/styles/Wrapper.styled';

export const StyledPullQuoteFull = styled.div<StyledPullQuoteFullProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  @media (min-width: 1280px) {
    flex-direction: row;
  }

  .pull-quote-container {
    padding: var(--gutter);
    @media (min-width: 1280px) {
      display: flex;
      width: 50%;
    }
  }

  .pull-quote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: ${({ cardColor }) => `var(--${cardColor});`};
    border-radius: 12px;
    padding: calc(var(--gutter) * 2) var(--gutter);

    h2 {
      font: 300 ${pxtorem(28)} / 100% var(--primaryFont);

      @media (min-width: 768px) {
        font-size: ${pxtorem(40)};
      }

      &:first-child {
        margin-top: ${pxtorem(8)};
      }
    }

    p,
    ul {
      font: 300 ${pxtorem(12)} / 110% var(--primaryFont);
      @media (min-width: 768px) {
        font-size: ${pxtorem(20)};
      }
    }

    ul {
      list-style: none;
      margin-bottom: ${pxtorem(40)};
      li {
        font-weight: 400;
        margin-bottom: ${pxtorem(12)};
      }

      span {
        font-weight: 500;
        margin-right: ${pxtorem(20)};
        display: block;
        margin-bottom: ${pxtorem(6)};
        font-size: ${pxtorem(16)};
      }
    }

    @media (min-width: 768px) {
      padding: ${pxtorem(51)} ${pxtorem(58)};
      text-align: left;

      ul {
        max-width: 700px;
        li {
          display: grid;
          grid-template-columns: 1fr 5fr;

          span {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: ${pxtorem(20)};
            text-align: right;
          }
        }
      }
    }
  }

  .section-label {
    color: var(--black);
    font: 500 ${pxtorem(12)} / 117% var(--primaryFont);
    text-transform: uppercase;
    margin-bottom: ${pxtorem(6)};

    @media (min-width: 768px) {
      font-size: ${pxtorem(16)};
    }
  }

  .img-container {
    height: auto;
    aspect-ratio: 1;
    @media (min-width: 1280px) {
      width: 50%;
    }

    img {
      transform: scale(1.2);
      transition: transform;
      transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
      transition-duration: 2s;
    }

    &.visible img {
      transform: scale(1);
    }

    .section__image {
      height: 100%;
    }
  }

  img {
    z-index: 1;
    object-fit: cover;
    width: 100%;
  }

  .pull-quote__cta-container {
    .pull-quote__cta {
      min-width: 170px;
      font-size: ${pxtorem(20)};
      font-weight: 500;
    }
  }
`;

interface StyledPullQuoteFullProps {
  cardColor: ThemeColor;
}
