import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { StyledSocialContact } from './SocialContact.styled';
import { Cta } from '~/components/Generic/Cta/Cta';
import useSiteMetadataQuery from '~/hooks/graphql/queries/use-site-metadata';
import ShowOnScroll from '~/components/Generic/ShowOnScroll/ShowOnScroll';
import { ContentfulPageSection } from '~/types/types.d';

export const SocialContact: React.FC<SocialContactProps> = ({ section }) => {
  const { site } = useSiteMetadataQuery();
  const instagramHandle = site.siteMetadata.social?.instagram;
  const instagramUrl = `https://www.instagram.com/${instagramHandle}/`;

  const title = section?.title || 'Know more about us & talk to our experts';
  const customBgMedia = section?.media ? section.media[0].media : null;

  return (
    <StyledSocialContact customBgMedia={customBgMedia?.file?.url}>
      <div className="text-container">
        <ShowOnScroll>
          <h2>{title}</h2>
        </ShowOnScroll>

        <ul>
          <li>
            <ShowOnScroll delay={100}>
              <Cta href={instagramUrl} variant="secondary" size="large">
                Instagram
              </Cta>
            </ShowOnScroll>
          </li>
          <li>
            <ShowOnScroll delay={150}>
              <Cta href="/faq" variant="secondary" size="large">
                FAQ
              </Cta>
            </ShowOnScroll>
          </li>
          <li>
            <ShowOnScroll delay={200}>
              <Cta href="/contact" variant="secondary" size="large">
                Contact
              </Cta>
            </ShowOnScroll>
          </li>
        </ul>
      </div>

      {/* {!customBgMedia && (
        <figure className="image image--standard">
          <StaticImage
            alt={site.siteMetadata.title}
            src="../../../assets/images/socialcontact.png"
          />
        </figure>
      )} */}
    </StyledSocialContact>
  );
};

interface SocialContactProps {
  section?: ContentfulPageSection;
}
