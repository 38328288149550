import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledFeaturedArticles = styled.div`
  padding-inline: min(5%, ${pxtorem(68)});

  .text__content {
    width: 80%;
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 60%;
    }

    h1 {
      text-align: center;
      font: 300 ${pxtorem(60)} / 97% var(--primaryFont);
      font-size: ${pxtorem(37)};
      margin: 0;
      margin-top: ${pxtorem(10)};
      em {
        font-size: 0.9em;
      }

      @media (min-width: 768px) {
        font-size: clamp(${pxtorem(30)}, 5vw, ${pxtorem(60)});
      }
    }
    p {
      text-align: center;
      font-size: ${pxtorem(20)};
      margin: 0;
      margin-top: ${pxtorem(10)};
    }
  }

  .articles--grid {
    display: grid;

    place-content: center;
    place-items: top;
    grid-template-columns: minmax(360px, 1fr);
    gap: ${pxtorem(40)};

    justify-items: center;
    margin: ${pxtorem(40)} auto ${pxtorem(60)};

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(360px, 1fr));
      gap: ${pxtorem(80)} var(--gutter);
    }

    @media (min-width: 1280px) {
      grid-template-columns: repeat(3, minmax(380px, 1fr));
    }
  }

  .article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    &__card {
      .gatsby-image-wrapper,
      img {
        aspect-ratio: 36 / 26;
      }

      .gatsby-image-wrapper {
        border-radius: var(--radius);
        overflow: hidden;
      }

      img {
        width: 100%;
        object-fit: cover;
      }

      @media (max-width: 1210px) {
        max-width: 100%;
        width: 100%;
      }
    }

    &__title {
      font: 500 ${pxtorem(16)} / 117% var(--primaryFont);
      text-transform: uppercase;
      margin: 8px 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    }

    &__description {
      p {
        margin: 0;
      }
    }

    &__date {
    }
  }
`;
