import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledValuePropGrid = styled.div<StyledValuePropGridProps>`
  padding: ${pxtorem(20)} ${pxtorem(45)};

  @media (min-width: 768px) {
    padding: ${pxtorem(65)} ${pxtorem(58)};
  }

  text-align: center;

  .section-label {
    color: var(--black);
    font: 500 ${pxtorem(16)} / 117% var(--primaryFont);
    text-transform: uppercase;
    margin-bottom: ${pxtorem(6)};
  }

  .grid {
    display: grid;
    place-content: center;
    justify-items: center;
    grid-template-columns: repeat(
      ${({ maxColumns }) => (maxColumns ? maxColumns : 3)},
      1fr
    );
    max-width: 1096px;
    margin: 0 auto;
    grid-row-gap: ${pxtorem(55)};

    @media (min-width: 768px) {
      margin-bottom: ${pxtorem(34)};
    }

    &-card {
      text-align: center;
      max-width: 282px;

      &--description p {
        font-size: ${pxtorem(12)};
        line-height: ${pxtorem(14)};
        margin: 0;

        @media (min-width: 768px) {
          font-size: ${pxtorem(16)};
          line-height: ${pxtorem(19)};
        }
      }
    }

    img {
      max-height: 47px;
    }

    @media (max-width: 1102px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .section-label {
    margin-top: ${pxtorem(18)};
    margin-bottom: ${pxtorem(12)};
  }

  a {
    font-size: ${pxtorem(20)};
    font-weight: 500;
  }
`;

interface StyledValuePropGridProps {
  maxColumns?: number;
}
