import React from 'react';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { StyledShopBy } from './ShopBy.style';
import { ContentfulPageSection } from '~/types/types';

type GatsbyImageData = GatsbyImageProps['image'];

export const ShopBy: React.FC<ShopByProps> = ({ section }) => {
  const SWIPER_OPTS: SwiperOptions = {
    modules: [Pagination],
    loop: false,
    pagination: true,
    preventClicksPropagation: false,
    touchStartPreventDefault: false,

    updateOnWindowResize: true,

    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
    },
  };

  return (
    <StyledShopBy>
      <ShowOnScroll className="shopby__text">
        <h1 dangerouslySetInnerHTML={{ __html: section.title }}></h1>
        <div
          dangerouslySetInnerHTML={{
            __html: section.description.childMarkdownRemark.html,
          }}
        />
      </ShowOnScroll>

      <Swiper {...SWIPER_OPTS} className="shopby__carousel">
        {section.media.map((card, i) => {
          let finalSlug = card.slug;
          if (card.__typename === 'ContentfulCollection') {
            finalSlug = `/shop/${card.productType ? card.slug : 'all'}/${card.productType ? 'all' : card.slug}/`.split('/all/all/').join('/');
          }
          return (
            <SwiperSlide key={`${card.slug}_${i}`}>
              <ShowOnScroll
                delay={100 * i}
                key={`${card.slug}_${i}`}
                className="shopby__card"
              >
                <a href={finalSlug} className="shopby__cardLink">
                  <figure>
                    <div className="shopby__cardImage">
                      <GatsbyImage
                        image={
                          card.mainImage.gatsbyImageData as GatsbyImageData
                        }
                        alt={card.title}
                      />
                    </div>
                    <figcaption>{card.title}</figcaption>
                  </figure>
                </a>
              </ShowOnScroll>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </StyledShopBy>
  );
};

interface ShopByProps {
  section: ContentfulPageSection;
}
