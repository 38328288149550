import React, { useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, SwiperOptions } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { StyledFeaturedProducts } from './FeaturedProducts.styled';
import { ProductCard } from '~/components/ProductCard/ProductCard';
import ShowOnScroll from '~/components/Generic/ShowOnScroll/ShowOnScroll';

import { IProduct, IntentSlug, ContentfulMedia } from '~/types/types';
import { intentSlugs } from '../../types/constants';
import { useMediaQuery } from '@mantine/hooks';

export const FeaturedProducts: React.FC<FeaturedProductsProps> = ({
  title,
  description,
  bgImage,
  products,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const nbProducts = products?.length;

  const SWIPER_OPTS: SwiperOptions = {
    modules: [Navigation, Pagination],
    pagination: true,
    navigation: {
      prevEl: '.featuredProducts-prev',
      nextEl: '.featuredProducts-next',
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      768: {
        slidesPerView: nbProducts >= 2 ? 2 : nbProducts,
        slidesPerGroup: nbProducts >= 2 ? 2 : nbProducts,
      },
      1280: {
        slidesPerView: nbProducts >= 3 ? 3 : nbProducts,
        slidesPerGroup: nbProducts >= 3 ? 3 : nbProducts,
      },
    },
    spaceBetween: 20,
    updateOnWindowResize: true,
  };

  return (
    <StyledFeaturedProducts>
      {bgImage && (
        <div className="background">
          <GatsbyImage image={bgImage.gatsbyImageData} alt={bgImage.title} />
        </div>
      )}

      {(title || description) && (
        <div className="text-container">
          {title && (
            <ShowOnScroll>
              <h1 className="text__title">{title}</h1>
            </ShowOnScroll>
          )}

          {description && (
            <ShowOnScroll
              delay={200}
              className="text__content"
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
          )}
        </div>
      )}

      <div className="slider-container">
        <div className="featuredProducts-prev"></div>

        <Swiper {...SWIPER_OPTS}>
          {products.map((product, index) => (
            <SwiperSlide key={`featuredProducts-${index}`}>
              <ShowOnScroll delay={50 * index}>
                <ProductCard
                  product={product}
                  intentCollections={product.collection?.filter(col =>
                    intentSlugs.includes(col.slug as IntentSlug)
                  )}
                  showSmallBtn={isMobile}
                />
              </ShowOnScroll>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="featuredProducts-next"></div>
      </div>
    </StyledFeaturedProducts>
  );
};

export interface FeaturedProductsProps {
  title?: string;
  description?: MarkdownData;
  bgImage?: ContentfulMedia;
  products: IProduct[];
}
