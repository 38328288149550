import styled from 'styled-components';
import { Wrapper } from '../../styles/Wrapper.styled';
import { pxtorem } from '../../utils/tools';

export const StyledVideoCarousel = styled.div`
  --x-padding: clamp(${pxtorem(40)}, 5%, ${pxtorem(80)});
  --y-padding: clamp(${pxtorem(40)}, 7.5%, ${pxtorem(96)});

  padding-top: var(--y-padding);
  padding-bottom: var(--y-padding);

  .VideoCarousel__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: var(--white);
    margin-bottom: 50px;
    padding: 0 var(--x-padding);
  }

  h2 {
    max-width: 20ch;
    margin-bottom: 0;
  }

  .slide-label {
    font: 500 ${pxtorem(16)} / 117% var(--primaryFont);
    text-transform: uppercase;
  }

  .slide-desc {
    font: 400 ${pxtorem(16)} / 117% var(--primaryFont);
  }

  .carousel {
    margin-inline: var(--x-padding);
    overflow: visible !important;
    min-height: calc(360px + 72px);
  }

  .swiper-slide {
    max-width: 360px;

    .gatsby-image-wrapper {
      aspect-ratio: 360 / 280;
      overflow: hidden;
      border-radius: 10px;
      width: 100%;
      max-width: 360px;
    }
  }

  .swiper-scrollbar {
    width: 100% !important;
    background-color: #ffffff1a;
    height: 1px !important;
    left: 0 !important;

    .swiper-scrollbar-drag {
      background: #fff;
      height: 1px;
    }
  }
`;
