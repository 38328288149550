import React, { useEffect, useState } from 'react';
import {
  Pagination,
  Scrollbar,
  SwiperOptions,
  Swiper as SwiperInstance,
  Navigation,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
  StyledTeamCarousel,
  StyledTeamCarouselCard,
} from './TeamCarousel.style';
import { useMediaQuery } from '@mantine/hooks';
import useCustomCursor from '~/hooks/components/use-custom-cursor';
import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import Shape4 from '~/assets/images/shapes/shape4.svg';
import { ContentfulPageSection } from '~/types/types';
import { useSwiperRef } from "~/hooks/components/use-swiper-ref";
import Arrow from '~/assets/images/icons/arrow-2.svg';

const SWIPER_OPTS: SwiperOptions = {
  loop: false,
  preventClicksPropagation: false,
  touchStartPreventDefault: false,
  slidesPerView: 'auto',
  grabCursor: true,
  modules: [Scrollbar, Navigation, Pagination],
  updateOnWindowResize: true,
  scrollbar: {
    draggable: true,
    dragSize: 522,
  },
  pagination: {
    dynamicBullets: false,
  },
  spaceBetween: 40,
};

export const TeamCarousel: React.FC<TeamCarouselProps> = ({ section }) => {
  const { title, media } = section;
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null);
  const mobileCard = useMediaQuery('(max-width: 768px)');
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();

  const swiperOptions = {
    ...SWIPER_OPTS,
    navigation: {
      nextEl: nextEl,
      prevEl: prevEl
    },
  }

  return (
    <StyledTeamCarousel>
      <ShowOnScroll>
        <div className="TeamCarousel__title">
          <h2>{title}</h2>

          <div className="SwiperNav SwiperNav--light">
            <button type="button" className="SwiperNav__item SwiperNav__item--prev" ref={prevElRef}>
              <Arrow />
            </button>

            <button type="button" className="SwiperNav__item SwiperNav_item--next" ref={nextElRef}>
              <Arrow />
            </button>
          </div>
        </div>
      </ShowOnScroll>

      <div className="carousel-container">
        <Swiper
          {...swiperOptions}
          className="carousel"
          onSwiper={swiperInstance => setSwiper(swiperInstance)}
        >
          {media.map((card, i) => {
            const [firstContent, secondContent] =
              card.description.childMarkdownRemark.html.split('<hr>');
            return (
              <SwiperSlide key={`${card.label}_${i}`}>
                <ShowOnScroll>
                  <StyledTeamCarouselCard>
                    <div className="flex-row">
                      <GatsbyImage
                        image={card.media.gatsbyImageData}
                        objectFit="cover"
                        alt={card.label}
                      />
                      <div className="content-container">
                        <p className="card-label">{card.label}</p>
                        <div
                          className="card-content"
                          dangerouslySetInnerHTML={{
                            __html: firstContent,
                          }}
                        />
                        {!mobileCard && (
                          <>
                            <hr />
                            <div
                              className="card-content"
                              dangerouslySetInnerHTML={{
                                __html: secondContent,
                              }}
                            />
                            <hr />
                          </>
                        )}
                      </div>
                    </div>
                    {mobileCard && (
                      <div className="mobile-content">
                        <hr />
                        <div
                          className="card-content"
                          dangerouslySetInnerHTML={{ __html: secondContent }}
                        />
                        <hr />
                      </div>
                    )}
                  </StyledTeamCarouselCard>
                </ShowOnScroll>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </StyledTeamCarousel>
  );
};

interface TeamCarouselProps {
  section: ContentfulPageSection;
}
