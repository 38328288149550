import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledProtocolCard = styled.article`
  text-align: center;
  margin-inline: var(--gutter);

  @media (min-width: 768px) {
    margin-inline: 0;
  }

  @media (min-width: 1280px) {
    max-width: 360px;
  }

  .protocol-card--thumbnail {
    width: 100%;
    /* aspect-ratio: 18 / 13; */
    border-radius: var(--radius);
    overflow: hidden;
    position: relative;
    &:after {
      content: '';
      display: block;
      padding-top: 72%;
    }
    > div, img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;

    }
  }

  .protocol-card--cardAuthor {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${pxtorem(20)} 0 ${pxtorem(10)};

    @media (min-width: 768px) {
      margin-top: ${pxtorem(30)};
    }

    span {
      font-size: ${pxtorem(12)};
      font-weight: 500;
      line-height: 1.2;
      margin-top: ${pxtorem(10)};

      @media (min-width: 768px) {
        font-size: ${pxtorem(16)};
      }
    }
  }

  .protocol-card--authorAvatar {
    width: ${pxtorem(45)};
    height: ${pxtorem(45)};
    border-radius: 100%;
    img {
      border-radius: 100%;
    }
  }

  .protocol-card--cardTitle {
    font-size: ${pxtorem(16)};
    font-weight: 400;
    line-height: 1.2;
    margin-top: ${pxtorem(10)};

    @media (min-width: 768px) {
      font-size: ${pxtorem(24)};
    }
  }

  a {
    text-decoration: none;
    color: var(--black);
    transition: color 100ms linear;
    border-radius: var(--radius);
    overflow: hidden;


    .protocol-card--thumbnail img {
      transition: transform 0.3s var(--cubic-ease);
    }

    &:hover {
      color: var(--green);

      .protocol-card--thumbnail img {
        transform: scale(1.06);
      }
    }
  }
`;
