import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';
import { StyledShowOnScroll } from '../Generic/ShowOnScroll/ShowOnScroll.style';

const SharedStyles = styled.div`
  padding: ${pxtorem(30)} 0;

  @media (min-width: 768px) {
    padding: ${pxtorem(90)} 0;
  }

  p {
    margin: 0;
  }

  .section-title {
    text-align: center;
    font: 500 ${pxtorem(16)} / 117% var(--primaryFont);
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 10px;

    @media (max-width: 1280px) {
      font-size: clamp(12px, 1.111vw, ${pxtorem(16)});
    }
  }

  .section-subtitle {
    text-align: center;
    margin-bottom: ${pxtorem(90)};

    h1 {
      font: 300 ${pxtorem(60)} / 97% var(--primaryFont);
      @media (max-width: 1280px) {
        font-size: clamp(${pxtorem(40)}, 4.166vw, ${pxtorem(60)});
      }
    }
  }
`;

export const StyledIngredientsModuleDesktop = styled(SharedStyles)`
  .grid {
    display: grid;
    padding-inline: ${pxtorem(80)};
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${pxtorem(40)};
    row-gap: ${pxtorem(76)};

    @media (max-width: 1300px) {
      padding-inline: var(--gutter);
    }
  }
`;

export const StyledIngredientsModuleMobile = styled(SharedStyles)`
  padding-inline: var(--gutter);
  .carousel {
    min-height: 450px;
  }

  .swiper-pagination-bullet {
    background: ${p => p.theme.colors.black}1a;

    &-active {
      background: var(--black);
    }
  }
`;

export const StyledIngredientModuleCard = styled.div`
  .container {
    display: flex;
    gap: ${pxtorem(24)};
  }

  .img-container {
    aspect-ratio: 185 / 248;
    width: 100%;
    max-width: 185px;
    border-radius: 5px;
    overflow: hidden;

    img {
      aspect-ratio: 185 / 248;
    }
  }

  hr {
    margin: 0;
    opacity: 0.2;
  }

  .bottom,
  .mobile-content {
    p {
      padding: 12px 0;
      margin: 0;

      strong {
        text-transform: uppercase;
        font-size: 12px;
        margin-right: ${pxtorem(16)};
        font-weight: 500;
      }

      @media (max-width: 1260px) {
        padding: 8px 0;
      }
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    .description {
      /* position: absolute; */
      bottom: 0;
      width: 100%;

      .bottom {
        /* bottom: 0; */
        /* width: 100%; */
        margin-top: ${pxtorem(16)};
      }
    }

    &.mobile {
      width: 100%;
      justify-content: flex-start;

      .description .bottom {
        display: none;
      }
    }
  }

  .mobile-content {
    margin-top: ${pxtorem(20)};

    hr:first-child {
      margin-bottom: ${pxtorem(8)};
    }

    hr:last-child {
      margin-top: ${pxtorem(12)};
    }

    .label {
      color: ${p => p.theme.colors.black}80;
      font-size: ${pxtorem(16)};
    }
  }

  .mobile-content .description a {
    display: none;
  }

  .label {
    font: 400 ${pxtorem(20)} / 117% var(--primaryFont);
    margin-bottom: 8px;
  }

  .description {
    a {
      display: block;
      text-transform: uppercase;
      font: 500 12px / 117% var(--primaryFont);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      &:first-child {
        margin-top: 12px;
      }

      &:last-child {
        margin-bottom: auto;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
`;
