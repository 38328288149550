import styled from 'styled-components';
import { ThemeColor } from '../../types/types';
import { pxtorem } from '../../utils/tools';
import { StyledLink } from '../Generic/Cta/Cta.style';

export const StyledPullQuoteBlob = styled.div<StyledPullQuoteBlobProps>`
  position: relative;
  padding: 147px min(5%, 102px);

  .section-label {
    color: var(--black);
    font: 500 ${pxtorem(12)} / 117% var(--primaryFont);
    text-transform: uppercase;
    margin-bottom: ${pxtorem(6)};

    @media (min-width: 768px) {
      font-size: ${pxtorem(16)};
    }
  }

  .pull-quote-blob {
    width: 100%;
    max-width: 676px;
    background-color: ${({ cardColor }) => `var(--${cardColor});`};
    border-radius: 12px;
    padding: calc(var(--gutter) * 2) var(--gutter);
    position: relative;
    z-index: 1;

    p {
      font: 300 ${pxtorem(28)} / 100% var(--primaryFont);
      margin-top: 0;
      &:first-child {
        margin-top: ${pxtorem(8)};
      }
    }

    @media (min-width: 1280px) {
      transform: translateX(-100%);
      opacity: 0;
      transition-property: transform opacity;
      transition-duration: 0.7s;
      transition-timing-function: var(--cubic-ease);
      &.visible {
        opacity: 1;
        transform: translateX(0%);
      }
    }

    @media (min-width: 768px) {
      padding: ${pxtorem(51)} ${pxtorem(58)};
      p {
        font-size: ${pxtorem(40)};
      }
    }

    @media (max-width: 767px) {
      margin-bottom: ${pxtorem(30)};
      a[class*='Cta'] {
        width: auto;
      }
    }
  }

  .flex-between {
    max-width: 1280px;
    margin-inline: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${pxtorem(48)};
  }

  @media (max-width: 768px) {
    padding: ${pxtorem(20)} min(5%, 102px);

    .pull-quote {
      padding: ${pxtorem(20)} ${pxtorem(24)};

      p {
        font-size: ${pxtorem(28)};
      }
    }
  }

  img {
    z-index: 1;
    border-radius: 10px;
    object-fit: contain;
    width: 100%;
  }

  svg {
    position: absolute;
    right: calc(var(--gutter) * -1);
    top: 147px;
    z-index: 0;

    path {
      fill: ${({ blobColor }) => `var(--${blobColor})`};
    }
  }

  .img-container {
    height: auto;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 453 / 485;
    max-width: 452px;

    @media (min-width: 1280px) {
      transform: translateX(100%);
      opacity: 0;
      transition-property: transform opacity;
      transition-duration: 0.7s;
      transition-timing-function: var(--cubic-ease);
      &.visible {
        opacity: 1;
        transform: translateX(0%);
      }
    }

    @media (max-width: 767px) {
      width: 70%;
      height: auto;
      margin-inline: auto;
    }
  }

  ${StyledLink} {
    font-weight: 500;
  }

  @media (max-width: 1279px) {
    .flex-between {
      flex-direction: column;
    }

    svg {
      top: unset;
      bottom: 0;
      width: 90vw;
      height: auto;
    }
  }
`;

interface StyledPullQuoteBlobProps {
  blobColor: ThemeColor;
  cardColor: ThemeColor;
}
