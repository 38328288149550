import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledTeamCarousel = styled.div`
  text-align: center;
  max-width: calc(100% - var(--gutter) * 2);
  margin-inline: auto;
  color: var(--white);
  position: relative;

  @media (min-width: 768px) {
    padding: ${pxtorem(20)} 0 ${pxtorem(100)};
  }

  .carousel-container {
    position: relative;
  }

  .TeamCarousel__title {
    display: flex;
    justify-content: space-between;
    color: var(--white);
    padding: 0 var(--x-padding);
  }

  .TeamCarousel__cursor {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(var(--cursorLeft)) translateY(var(--cursorTop));
    z-index: 3;
    pointer-events: none;
    transition: transform 0.1s linear, opacity 0.2s ease-in-out;
    &Inner {
      transform: translateX(-50%) translateY(-50%);
    }
    span {
      position: absolute;
      top: 50%;
      pointer-events: none;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
      color: #fcfaf3;
      transition: opacity 0.2s ease-in-out;
    }
    svg {
      fill: var(--red);
      width: 137px;
      transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
    }
    &--is-dragging {
      svg {
        width: 45px;
      }
      span {
        opacity: 0;
      }
      opacity: 0.85;
    }
    &--is-hovering {
      svg {
        width: 10px;
      }
      span {
        opacity: 0;
      }
      opacity: 0.85;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .swiper-scrollbar {
    background-color: #ffffff1a;
    height: 1px !important;
    width: 100% !important;
    left: 0 !important;
    display: none;
  }

  .swiper-pagination {
    bottom: -20px;

    &-bullet {
      background-color: var(--white);
      opacity: 0.1;

      &-active {
        background-color: var(--white);
        opacity: 1;
      }
    }
  }

  .carousel {
    min-height: calc(249px + 64px);
    width: 100%;

    overflow: visible;

    @media (max-width: 700px) {
      min-height: 360px;
    }
  }

  .swiper-scrollbar-drag {
    height: 1px;
    background-color: var(--white);
  }

  .swiper-slide {
    max-width: 620px;

    @media (min-width: 768px) {
      padding: 0 calc(var(--gutter) * 2) 0 var(--gutter);
      max-width: 620px;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  @media (min-width: 1440px) {
    max-width: min(88vw, 1700px);
  }

  @media (min-width: 768px) {
    .swiper-scrollbar {
      display: block;
    }

    .swiper-pagination {
      display: none;
    }
  }
`;

export const StyledTeamCarouselCard = styled.div<StyledCarouselCardProps>`
  --big-text: 500 ${pxtorem(20)} / 117% var(--primaryFont);
  --md-text: 400 ${pxtorem(16)} / 117% var(--primaryFont);
  --sm-text: 500 ${pxtorem(12)} / 117% var(--primaryFont);
  text-align: left;
  max-width: 620px;

  .flex-row {
    display: flex;
    gap: ${pxtorem(24)};
    align-items: stretch;
    justify-content: space-between;
  }

  .gatsby-image-wrapper {
    max-width: 96px;
    width: 100%;
    aspect-ratio: 96/128;
    border-radius: 10px;

    @media (min-width: 768px) {
      aspect-ratio: 185 / 249;
      max-width: 185px;
    }
  }

  hr {
    color: var(--offWhite);
    opacity: 0.2;
    width: 100%;

    margin-top: ${pxtorem(12)};
  }

  .content-container,
  .mobile-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-label {
      font: var(--big-text);
      color: var(--offWhite);
      margin: 0;
      @media (max-width: 768px) {
        font: var(--md-text);
      }
    }

    p > strong {
      color: var(--white);
      text-transform: uppercase;
      font: var(--sm-text);
    }

    p > a {
      color: var(--white);
      margin-left: 40px;
      font: var(--md-text);
    }
  }

  .card-content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    p {
      margin: 0;
      font: var(--md-text);
      color: ${p => p.theme.colors.offWhite}B3;
      margin-top: ${pxtorem(8)};
    }
  }
`;

interface StyledCarouselCardProps {
  imgWidth?: number;
  imgHeight?: number;
}
