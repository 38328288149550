import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, SwiperOptions } from 'swiper';
import { GatsbyImage } from 'gatsby-plugin-image';
import 'swiper/css';
import 'swiper/css/scrollbar';

import ShowOnScroll from '../Generic/ShowOnScroll/ShowOnScroll';
import { ContentCard, ContentfulPageSection } from '~/types/types';
import Arrow from '~/assets/images/icons/arrow-2.svg';

import { StyledVideoCarousel } from './VideoCarousel.style';
import { useSwiperRef } from "~/hooks/components/use-swiper-ref";

const SWIPER_OPTS: SwiperOptions = {
  loop: false,
  preventClicksPropagation: false,
  touchStartPreventDefault: false,
  slidesPerView: 'auto',
  spaceBetween: 20,
  updateOnWindowResize: true,
  modules: [Scrollbar, Navigation],
  grabCursor: true,
  scrollbar: {
    dragSize: 522,
    draggable: true,
  },
};
export const VideoCarousel: React.FC<VideoCarouselProps> = ({ section }) => {
  const [prevEl, prevElRef] = useSwiperRef<HTMLButtonElement>();
  const [nextEl, nextElRef] = useSwiperRef<HTMLButtonElement>();

  const swiperOptions = {
    ...SWIPER_OPTS,
    navigation: {
      nextEl: nextEl,
      prevEl: prevEl
    },
  }

  return (
    <StyledVideoCarousel>
      <ShowOnScroll>
        <div className="VideoCarousel__title">
          <h2>{section.title}</h2>

          <div className="SwiperNav SwiperNav--light">
            <button type="button" className="SwiperNav__item SwiperNav__item--prev" ref={prevElRef}>
              <Arrow />
            </button>

            <button type="button" className="SwiperNav__item SwiperNav_item--next" ref={nextElRef}>
              <Arrow />
            </button>
          </div>
        </div>
      </ShowOnScroll>

      <Swiper {...swiperOptions} className="carousel">
        {section.media.map(({ label, media, id, description }: ContentCard) => {
          return (
            <SwiperSlide key={id}>
              {media?.gatsbyImageData && (
                <ShowOnScroll delay={100}>
                  <GatsbyImage
                    image={media.gatsbyImageData}
                    alt={label}
                    objectFit="cover"
                  />
                </ShowOnScroll>
              )}
              <ShowOnScroll delay={100}>
                <p className="slide-label">{label}</p>
              </ShowOnScroll>
              <ShowOnScroll delay={300}>
                <p
                  className="slide-desc"
                  dangerouslySetInnerHTML={{
                    __html: description.childMarkdownRemark.html,
                  }}
                />
              </ShowOnScroll>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </StyledVideoCarousel>
  );
};

interface VideoCarouselProps {
  section: ContentfulPageSection;
}
