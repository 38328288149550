import React from 'react';
import { ContentCard, ContentfulPageSection } from '~/types/types';

import { StyledPillars } from './Pillars.styled';

export const Pillars: React.FC<PillarsProps> = ({ section }) => {
  return (
    <StyledPillars>
      <h2>{section.title}</h2>
      <div className="pillars-grid">
        {section.media.map(({ media, label, id }: ContentCard) => {
          const { width, height } = media.file.details.image;
          return (
            <div key={id} className="pillar">
              <img src={media.file.url} width={width} height={height} />
              <p>{label}</p>
            </div>
          );
        })}
      </div>
    </StyledPillars>
  );
};

interface PillarsProps {
  section: ContentfulPageSection;
}
